/**
 * @author zhongxinzhi
 * @date 2020/8/23 7:05 下午
 */
// @todo 参考clue发送埋点兼容
import Core, { IConfig, shadowMerge, toQueryParams } from '@alife/cone-arms-core';
import { getWebUid, getMiniAppUid, isNotEmptyFunction, getSendInfo } from './util';
import jsPluginError from '@ali/trace-plugin-error';
import defaultSetting from '../setting.json';

declare global {
  const wx: any;
  const my: any;
  const dd: any;
}

const sdk_version = defaultSetting.sdkVersion;

let instance: Core;

const TraceSdk = <T extends Core = Core>(config: IConfig, pipe?: any[]) => {
  const defaultConfig = {
    useSendBeacon: true,
    errorEnable: true,
    ignoreErrors: [],
    enableAllRejection: false,
    singleton: true,
  };
  const finalConfig = shadowMerge(defaultConfig, config);
  const pluginConfig = finalConfig.plugins || [];
  const { singleton } = finalConfig;
  if (finalConfig.errorEnable === true) {
    pluginConfig.push([
      jsPluginError,
      {
        ignoreErrors: finalConfig.ignoreErrors,
        enableAllRejection: finalConfig.enableAllRejection,
      },
    ]);
  }
  finalConfig.plugins = pluginConfig;
  if (singleton && instance) {
    return instance;
  } else {
    const core = new Core(finalConfig, pipe);
    if (singleton) {
      instance = core;
    }

    // 兼容小程序发送
    const miniApp =
      (typeof wx === 'object' && wx) ||
      (typeof my === 'object' && my) ||
      (typeof dd === 'object' && dd);

    if (miniApp && typeof miniApp.request === 'function' && isNotEmptyFunction(miniApp.request)) {
      // 覆盖获取uid的方法
      core.getUidFromCookie = getMiniAppUid;
      // 覆盖send方法
      core.send = (param: { [key: string]: any }) => {
        param.sdk_version = sdk_version;
        const sendInfo = getSendInfo(param.type, config.aplusUrl);
        const { APLUS_URL } = sendInfo;
        const gokey = toQueryParams(param, true);
        miniApp.request({
          url: APLUS_URL,
          data: JSON.stringify({
            gmkey: 'OTHER',
            gokey,
            logtype: '2',
          }),
          method: 'post',
          dataType: 'base64',
          header: {
            'content-type': 'application/json', // 默认值
          },
          success() {},
        });
      };
    } else if (
      finalConfig.useSendBeacon &&
      typeof navigator === 'object' &&
      typeof navigator.sendBeacon === 'function'
    ) {
      // 覆盖获取uid的方法
      core.getUidFromCookie = getWebUid;
      // 覆盖send方法
      core.send = (param: { [key: string]: any }) => {
        param.sdk_version = sdk_version;
        const sendInfo = getSendInfo(param.type, config.aplusUrl);
        const { APLUS_URL } = sendInfo;
        const gokey = toQueryParams(param, true);
        navigator.sendBeacon(
          APLUS_URL,
          JSON.stringify({
            gmkey: 'OTHER',
            gokey,
            logtype: '2',
          }),
        );
      };
    } else if (typeof window === 'object' && window && typeof document === 'object' && document) {
      // 覆盖获取uid的方法
      core.getUidFromCookie = getWebUid;
      // 覆盖send方法
      core.send = (param: { [key: string]: any }) => {
        param.sdk_version = sdk_version;
        const sendInfo = getSendInfo(param.type, config.aplusUrl);
        const { APLUS_URL } = sendInfo;
        const gokey = toQueryParams(param, false);
        const img = new Image();
        img.src = `${APLUS_URL}?${gokey}`;
      };
    }

    return core as T;
  }
};

export default TraceSdk;
