import Core from '@alife/cone-arms-core';
import {
  ICustomEventReportInitOptions,
  ICustomEventReportOptions,
  ITrace,
  ILogLibVersionItem,
  ILogHottagItem,
  ILogFilterItem,
} from './types';
import CustomEventManager from './custom-event-manager';
import { getCookie } from './utils';

export const extendTraceInstance = (originTrace: Core): ITrace => {
  const callInstanceMethod = (
    instanceId: string,
    method: 'logStart' | 'logEnd',
    options?: Partial<ICustomEventReportOptions>,
  ) => {
    const instance = CustomEventManager.getInstance(instanceId);
    if (instance && typeof instance[method] === 'function') {
      instance[method](options);
    }
  };
  const newTrace = Object.assign(originTrace, {
    initCustomEventReport: (initOptions: ICustomEventReportInitOptions) => {
      return new CustomEventManager(newTrace, initOptions);
    },
    destroyCustomEventReportInstance: CustomEventManager.destroyInstance,
    reportCustomEventStart: (
      instanceId: string,
      options: Partial<ICustomEventReportOptions> = {},
    ) => {
      callInstanceMethod(instanceId, 'logStart', options);
    },
    reportCustomEvent: CustomEventManager.log,
    reportCustomEventEnd: (
      instanceId: string,
      options: Partial<ICustomEventReportOptions> = {},
    ) => {
      callInstanceMethod(instanceId, 'logEnd', options);
    },
    reportLibVersion(logItem: ILogLibVersionItem) {
      if (logItem && logItem.libName && logItem.version) {
        newTrace.logCustom({
          p1: 'cn-lib-version-report',
          c1: logItem.libName,
          c2: logItem.version,
        });
      }
    },
    reportHottag(logItem: ILogHottagItem) {
      if (logItem) {
        const { name = '', eventType = 'CLK', group = '', hottag, ...rest } = logItem;
        // @ts-ignore logEvent中定义的xpath字段类型有误
        newTrace.logEvent({
          ...rest,
          id: 'cn-hottag-report',
          c1: hottag,
          eventType,
          c2: name,
          c3: group,
        });
      }
    },
    reportFilter(logItem: ILogFilterItem) {
      if (logItem) {
        const { allFields = [], filterFields = [], api = '', ...rest } = logItem;
        newTrace.logCustom({
          ...rest,
          p1: 'cone-ftp-filter-report',
          c5: JSON.stringify(allFields),
          c6: JSON.stringify(filterFields),
          c7: api,
          c8: allFields
            .filter(
              (item) =>
                item &&
                filterFields.find((filterItem) => filterItem && item.key === filterItem.key),
            )
            .map((item) => item.label)
            .join(','),
        });
      }
    },

    markSPAChanged() {},
  });
  const originGetUidFromCookie = newTrace.getUidFromCookie;
  newTrace.getUidFromCookie = () => {
    const cookieNames = ['accountId', 'empId', 'currentUserNo'];
    let uid = '';
    for (let i = 0; i < cookieNames.length; i++) {
      uid = getCookie(cookieNames[i]);
      if (uid) {
        break;
      }
    }
    if (uid) {
      return uid;
    }
    return typeof originGetUidFromCookie === 'function' ? originGetUidFromCookie() : '';
  };
  return newTrace;
};
